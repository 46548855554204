import React from 'react'
import Helmet from 'react-helmet'

import { graphql } from 'gatsby'

import BarTile from '../components/BarTile'

import './style.scss'
import Layout from '../layouts'

const TopBars = ({ bars }) => (
  <div className="columns is-multiline">
    {bars.map((bar) => <BarTile {...bar} key={bar.id} />)}
  </div>
)

const CheapestBarsInCity = ({ data, pathContext }) => {
  const bars = data.regexBars.edges.map((edge) => edge.node)
  return (
    <Layout>
      <section className="section">
        <div className="container">
          <Helmet title={`MisterGoodBeer | Top des bars en "${pathContext.letter}"`} />
          <h1 className="title">
            {`Les ${bars.length} bars qui commencent par "${pathContext.letter}"`}
          </h1>
          <TopBars bars={bars} />
        </div>
      </section>
    </Layout>
  )
}

export default CheapestBarsInCity


export const pageQuery = graphql`
  query BarsByRegex ($regex: String) {
    regexBars : allMisterGoodBeerBar (
      filter: {
        name: {regex: $regex},
      },
      sort: {order: ASC, fields: [name]}
      )
    {
      edges {
        ...barFragment2
      }
    }
  }

  fragment barFragment2 on MisterGoodBeerBarEdge {
    node {
      id
      slug
      name
      address
      regularPrice
      happyHourPrice
      startHappyHour { iso }
      endHappyHour { iso }
    }
  }
`
